@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&family=Quicksand:wght@300..700&family=Shantell+Sans:ital,wght@0,300..800;1,300..800&display=swap');

@layer utilities {

  .text-cut {
    display: inline-block; /* or block, depending on your layout */
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; /* This adds "..." at the end */
  }

  .text-cut-description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal; /* Allows text to wrap */
    -webkit-line-clamp: 3; /* Change this number to the number of lines you want to display */
  }


  .text-cut-20 {
    display: inline-block; /* or block, depending on your layout */
    max-width: 20ch; /* Replace 20 with the number of characters you want */
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; /* This adds "..." at the end */
  }

  .text-cut-14 {
    display: inline-block; /* or block, depending on your layout */
    max-width: 14ch; /* Replace 20 with the number of characters you want */
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; /* This adds "..." at the end */
  }
}

body {
  margin: 0;
  font-family: 'Quicksand', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* font-family: "Shantell Sans", cursive;
font-family: "Permanent Marker", cursive; */


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
