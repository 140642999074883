.app {
  text-align: center;
}

body {
  min-height: 100vh;
  background: #ececec;
  /* background: linear-gradient(125deg, #CEE2BE 0%, #9dd5ee 33%, #8d9bca 66%, #b1ff9c 100%); */
  /* background: linear-gradient(125deg, #CEE2BE 0%, #9dd5ee 33%, #8d9bca 66%, #FFACAC 100%); */
  background-size: 300% 300%;
  animation: gradient 15s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}


/* body {
  background-image: url('../public/bgd.png');
  background-position: left;
  background-size: cover;
} */
.admin-page {
  display: flex;
  min-height: 75vh;
  flex-direction: column;
  margin-bottom: 5rem;
}

.admin-login {
  min-height: 75vh;
  margin: auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user-page, .register-page, .home-page {
  min-height: 100vh;
  margin: auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.logo:has(+ .home-page), .logo:has(+ .admin-page), .logo:has(+ .user-page) {
  display: none;
}

.home-page .navbar {
  background-color: transparent;
}

/*  #root {
    min-height: 75vh;
  margin: auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
} 
*/

.app-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.app-link {
  color: #61dafb;
}

.mark-timely {
  font-family: "Kaushan Script", cursive;
  font-size: 3rem;
  font-weight: normal;
  font-style: italic;
}

h1 {
  font-size: 2.2rem;
  font-weight: bold;
  /* text-align: left; */
  /* margin-bottom: 2rem; */
}

h2 {
  font-size: 1.6rem;
  font-weight: bold;
  /* text-align: left; */
  margin-bottom: 0.5rem;
}

.user-page hr{
  margin: 0.2rem 0rem;
}

hr{
  margin: 0.4rem 0rem;
}

.input:focus, .input:focus-within {
  box-shadow: none;
  border-color: transparent;
  outline-style: solid;
  /* outline-width: 0px; */
  outline-offset: 0px;
  outline-color: transparent;
}

.criterias {
  font-size: 0.9rem;
  text-align: left;
  color: black;
  background-color: rgb(255 255 255 / 40%);
  padding: 1rem;
  border-radius: 0.5rem;

  .criterias-title {
    margin-bottom: 4px;
  }

  ul {
    margin-bottom: 0px;
    padding-left: 16px;

    li {
      span {
        font-weight: 700;
      }
    }
  }
}

.badge-base {
  height: 1.4rem;
  font-size: 0.9rem;
  line-height: 1.4rem;
  padding-left: 0.438rem;
  padding-right: 0.438rem;
}

.close-alert {
  position: relative;
  cursor: pointer;
  top: -10px;
  right: -6px;
  font-weight: bold;
  font-size: 0.7rem;
  border: 1px solid black;
  border-radius: 2rem;
  padding: 0.05rem 0.34rem;
}

.rdp-day, .rdp-weekdays {
  font-weight: 600 !important;
  font-size: 1.2rem !important;
  color: #070707 !important;
}

.rdp-disabled {
  font-weight: 600 !important;
  font-size: 1.2rem !important;
  opacity: 0.3 !important;
}

.rdp-chevron {
  fill: #070707 !important;
}

.rdp-today .rdp-day_button {
  color:#070707 !important;
  border: 2px solid #070707 !important;
  border-radius: 50rem !important;
}

.rdp-focused .rdp-day_button {
  background-color:#070707 !important;
  color:#fdfdfd !important;
  border: 2px solid #070707 !important;
  border-radius: 50rem !important;
}


.ql-align-center {
  text-align: center;
}

.ql-align-justify {
  text-align: justify;
}

.ql-align-right {
  text-align: right;
}

.ql ul {
  list-style: disc;
  margin-left: 0.8rem;
}

.ql ul li, .ql ol li {
  padding-left: 0.8rem;
}

.ql ol {
  list-style: decimal;
  margin-left: 0.8rem;
}


.ql blockquote {
  font-style: italic;
  quotes: "\201C""\201D""\2018""\2019";
}
.ql blockquote:before {
  content: open-quote;
  font-size: 3em;
  line-height: 0.1em;
  margin-right: 0.3em;
  vertical-align: -0.4em;
}

.ql blockquote:after {
  content: close-quote;
  font-size: 3em;
  line-height: 0.1em;
  margin-left: 0.1em;
  vertical-align: -0.4em;
}
.ql blockquote p {
  display: inline;
}

.logo {
  left: 0px;
  padding: 1rem 0rem 0rem 1rem;
  font-weight: 600;
}


.new-div {
  display: block;
  position: absolute;
  height: inherit;
  width: inherit;
  background: whitesmoke;
  z-index: 9999;
}

.menu li > *:not(ul, .menu-title, details, .btn):active, .menu li > *:not(ul, .menu-title, details, .btn).active, .menu li > details > summary:active {
  background: inherit !important;
  color: inherit !important;
}

.btn-outline:hover {
  color:  inherit !important;
}

.ql-editor {
  height: 10rem !important
}